import  { type InitOptions } from "i18next"

const config: InitOptions = {
  supportedLngs: ["en", "es-MX"],
  fallbackLng: "en",
  defaultNS: "common",
  react: { useSuspense: false },
}

export default config
